<section
  *ngIf="audio"
  class="sections "
  style="margin-bottom: 0; padding: 1% 5% 1% 5%"
>
  <div class="container pb-2">
    <div class=" card  pt-2 pb-2 col-sm shadow">
    <h1>{{ audio.titulo }}</h1>
    <mat-divider></mat-divider>
    <div class="share-social mb-2">
      <button mat-icon-button shareButton="facebook" #fbBtn="shareButton">
        <fa-icon
          *ngIf="fbBtn"
          [icon]="['fab', 'facebook-square']"
          size="lg"
          [style.color]="fbBtn?.color"
        ></fa-icon>
      </button>
      <button mat-icon-button shareButton="messenger" #msBtn="shareButton">
        <fa-icon
          *ngIf="msBtn"
          [icon]="msBtn.icon"
          [style.color]="msBtn?.color"
          size="lg"
        ></fa-icon>
      </button>
      <button mat-icon-button shareButton="twitter" #twtBtn="shareButton">
        <fa-icon
          *ngIf="twtBtn"
          [icon]="twtBtn.icon"
          [style.color]="twtBtn?.color"
          size="lg"
        ></fa-icon>
      </button>
      <button mat-icon-button shareButton="whatsapp" #whBtn="shareButton">
        <fa-icon
          *ngIf="whBtn"
          [icon]="['fab', 'whatsapp-square']"
          [style.color]="whBtn?.color"
          size="lg"
        ></fa-icon>
      </button>
      <button mat-icon-button shareButton="telegram" #telBtn="shareButton">
        <fa-icon
          *ngIf="telBtn"
          [icon]="telBtn.icon"
          [style.color]="telBtn?.color"
          size="lg"
        ></fa-icon>
      </button>
      <button mat-icon-button shareButton="email" #emBtn="shareButton">
        <fa-icon
          *ngIf="emBtn"
          [icon]="emBtn.icon"
          [style.color]="emBtn?.color"
          size="lg"
        ></fa-icon>
      </button>
      <button mat-icon-button shareButton="copy" #cpBtn="shareButton">
        <fa-icon
          *ngIf="cpBtn"
          [icon]="cpBtn.icon"
          [style.color]="cpBtn?.color"
          size="lg"
        ></fa-icon>
      </button>
    </div>

    <div class="row">
      <div class="col-auto">
        <img
          loading="lazy"
          *ngIf="audio.categoria === null || audio.categoria.tipo !== 'PEOPLE'"
          src="https://radioagudo.com.br/{{ audio.foto }}"
          style="max-height: 200px; max-width: 100%"
          [alt]="audio.titulo"
        />
      </div>
      <div class="col-sm">
        <div  [innerHTML]="audio.conteudo">
        
        </div>
        <button
        class="btn"
        style="background: #0b7f9c; color: white"
        mat-button
        (click)="changeFont(audio)"
      >
        <fa-icon [icon]="['fas', 'play']"></fa-icon> Reproduzir entrevista
      </button>
      </div>
      

    </div>
    </div>
  </div>
</section>
<section class="sections" style="margin-bottom: 0; padding: 1% 5% 5% 5%">
  <div class="container">
    <div class="row">
      <div
        class="col-sm"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        (scrolled)="nextBatch($event)"
      >
        <h2 id="audiosList">Áudios</h2>
        <div
          (click)="changeFont(audio)"
          *ngFor="let audio of audios"
          class="card col-sm shadow"
          style="
            min-width: 30%;
            margin: 10px 10px 10px 0px;
            cursor: pointer;
            border: 1px solid #ccc;
          "
        >
          <div class="row">
            <div
              *ngIf="
                audio.categoria !== null &&
                audio.categoria.img !== null &&
                audio.categoria.tipo === 'PEOPLE' &&
                audio.categoria.img !== ''
              "
            >
              <img
                loading="lazy"
                *ngIf="audio.categoria.tipo === 'PEOPLE'"
                src="https://radioagudo.com.br/{{ audio.categoria.img }}"
                style="max-height: 200px; max-width: 100%"
                [alt]="audio.titulo"
              />
            </div>
            <div
              *ngIf="!audio.foto.includes('semfoto') && audio.foto != ''"
              class="col-4"
              style="padding: 0"
            >
              <img
                loading="lazy"
                *ngIf="
                  audio.categoria === null || audio.categoria.tipo !== 'PEOPLE'
                "
                src="https://radioagudo.com.br/{{ audio.foto }}"
                style="max-height: 200px; max-width: 100%"
                [alt]="audio.titulo"
              />
            </div>
            <div class="col">
              <p style="margin-top: 7px; margin-bottom: 3px">
                <span
                  [routerLink]="
                    '/audios/' + this.removeAcento(audio.categoria.nome)
                  "
                  [matTooltip]="
                    'Clique para ver mais áudios de ' + audio.categoria.nome
                  "
                  class="bg-blue"
                  style="color: white; border-radius: 5px; padding: 2px"
                  *ngIf="audio.categoria !== null"
                >
                  {{ audio.categoria.nome }}
                </span>
              </p>
              <h3 style="padding: 10px 0 0 0">{{ audio.titulo }}</h3>
              <p>{{ removeHtmlTags(audio.conteudo) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <mat-list>
          <div mat-subheader><h2>Categorias</h2></div>
          <mat-list-item>
            <button
              routerLink="/audios"
              fragment="audiosList"
              mat-raised-button
              class="shadow-none"
            >
              <fa-icon
                [icon]="['fas', 'file-audio']"
                style="font-size: 1.5rem; margin-right: 5px"
              ></fa-icon>
              Todas categorias
            </button>
            <mat-divider></mat-divider>
          </mat-list-item>
          <ng-container *ngFor="let category of audioCategories">
            <ng-container *ngIf="category.tipo === 'GENERAL'">
              <mat-list-item>
                <button
                  [routerLink]="'/audios/' + removeAcento(category.nome)"
                  fragment="audiosList"
                  mat-raised-button
                  class="shadow-none"
                >
                  <fa-icon
                    [icon]="['fas', 'file-audio']"
                    style="font-size: 1.5rem; margin-right: 5px"
                  ></fa-icon>
                  {{ category.nome }}
                </button>
              </mat-list-item>
              <mat-divider></mat-divider>
            </ng-container>
          </ng-container>
        </mat-list>
      </div>
    </div>
  </div>
</section>
